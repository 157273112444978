import { Hidden } from '@material-ui/core';
import React from 'react';
import LinearCTAButton from '../LinearCTAButton';
import styles from './HomeSecurity.module.scss';

export default function HomeSecurity() {
  return (
    <section className={styles.section}>
      <div className={styles.container}>
        <p className={styles.text}>
          ペイディでは、お客様に安心・安全にサービスをご利用
          <Hidden xsDown>
            <br />
          </Hidden>
          いただけるよう、セキュリティ対策を強化しています。
        </p>
        <LinearCTAButton text="安心・安全の取り組みへ" to="/security" />
      </div>
    </section>
  );
}
