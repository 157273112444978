import React from 'react';
import { Link } from 'gatsby';
import ImgHowToUse1 from 'src/images/how-to-use-1.png';
import ImgHowToUse2 from 'src/images/how-to-use-2.png';
import ImgHowToUse3 from 'src/images/how-to-use-3.png';
import ImgHowToUse4 from 'src/images/how-to-use-4.png';
import styles from './StepsWithBrownBackground.module.scss';

const TITLE = 'あと払いペイディとは';

const DESCRIPTION =
  'AmazonやQoo10のほか、Visaマークのあるネットショップならどこでも、スマホだけで今すぐお買い物ができるあと払いサービスです。お支払いは翌月でOK。さらに、リアルカードを発行すると、Visaマークのある街のお店でもペイディが利用可能に。';

const DATA = [
  {
    key: 'how-to-use-1',
    img: ImgHowToUse1,
    to: '/#how-to-pay',
  },
  {
    key: 'how-to-use-2',
    img: ImgHowToUse2,
    to: '/paidycard',
  },
  {
    key: 'how-to-use-3',
    img: ImgHowToUse3,
    to: '/npay',
    textDisclaimer: '*口座振替・銀行振込のみ無料',
  },
  {
    key: 'how-to-use-4',
    img: ImgHowToUse4,
    to: '/guide#budget',
  },
];

export default function StepsWithBrownBackground({
  title = TITLE,
  description = DESCRIPTION,
  data = DATA,
}) {
  return (
    <section className={styles.section}>
      <h1 className={styles.title}>{title}</h1>
      {description && <p className={styles.description}>{description}</p>}
      <div className={styles.container}>
        {data.map((item, index) => (
          <Link to={item.to} className={styles.item} key={item.key}>
            <img
              src={item.img}
              alt={`how-to-use-${index}`}
              className={styles.img}
            />
            {item.textDisclaimer && (
              <p className={styles.textDisclaim}>{item.textDisclaimer}</p>
            )}
          </Link>
        ))}
      </div>
    </section>
  );
}
