import React from 'react';
import img1 from 'src/images/how-to-pay-1.svg';
import img2 from 'src/images/how-to-pay-2.svg';
import img3 from 'src/images/how-to-pay-3.svg';
import img4 from 'src/images/how-to-pay-4.svg';
import styles from './HomeHowToPay.module.scss';
import PaymentHowToUse from '../Payments/PaymentHowToUse';

const DATA = [
  {
    key: 1,
    img: img1,
    text:
      'ショップのお支払い方法で「あと払い(ペイディ)」を選択し、メアドと携帯番号を入力。',
  },
  {
    key: 2,
    img: img2,
    text: 'SMSで届く4桁の認証コードを入力して購入。',
  },
  {
    key: 3,
    img: img3,
    text: '翌月1日～5日の間にメールやSMSでご請求金額をお知らせ。',
  },
  {
    key: 4,
    img: img4,
    text: '翌月27日までに、コンビニ払い、銀行振込、口座振替でお支払い。',
  },
];

const CUSTOM_CLASSNAME = {
  section: styles.section,
  text: styles.text,
};

export default function HomeHowToPay() {
  return (
    <PaymentHowToUse
      title="簡単4ステップでお買い物"
      data={DATA}
      textButton="使い方の詳細を見る"
      customClassName={CUSTOM_CLASSNAME}
      id="how-to-pay"
      to="/payments"
    />
  );
}
