/* eslint-disable import/prefer-default-export */
import { makeStyles } from '@material-ui/core/styles';

export const styles = makeStyles((theme) => ({
  wrapper: {
    backgroundColor: '#fafafa',
  },
  container: {
    padding: '80px 0 88px',
    color: '#1C1C1C',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  title: {
    font: `900 36px/46px ${theme.typography.fontFamily}`,
    '@media (max-width: 1024px)': {
      font: `900 28px/46px ${theme.typography.fontFamily}`,
    },
  },
  innerBlock: {
    width: 780,
    marginTop: 50,
    marginBottom: 84,
    '@media (max-width: 1024px)': {
      width: '100%',
    },
  },
  innerLinksBlock: {
    margin: '68px 0',
    display: 'flex',
    width: '600px',
    flexDirection: 'column',
    alignItems: 'flex-start',
    gap: '8px',
    '@media (max-width: 1024px)': {
      width: '100%',
      padding: '0 16px',
    },
  },
  accordion: {
    margin: '0 !important',
    borderBottom: '1px solid #C4BCB5',
    boxShadow: 'none',

    '&:before': {
      display: 'none',
    },
  },
  summary: {
    backgroundColor: '#fafafa',
    padding: '0 26px',

    '&:not(:first-child)': {},
  },
  summaryContent: {
    margin: '32px 0 !important',
  },
  details: {
    background: '#f0f0f0',
    padding: '32px 46px',
    font: `400 16px/24px ${theme.typography.fontFamily}`,
    color: '#404042',
    '& > p a': {
      color: '#e5258c',
      fontWeight: 400,
      textDecoration: 'underline',
    },
  },
  cq: {
    alignItems: 'center',
    backgroundColor: '#E6E1DC',
    borderRadius: '50%',
    color: '#1C1C1C',
    display: 'flex',
    font: `400 16px/16px ${theme.typography.fontFamily}`,
    height: 30,
    justifyContent: 'center',
    marginRight: 16,
    paddingBottom: 4,
    width: 30,
    '@media (max-width: 1024px)': {
      paddingRight: 8,
      paddingLeft: 8,
    },
  },
  heading: {
    font: `700 18px/32px ${theme.typography.fontFamily}`,
    '@media (max-width: 1024px)': {
      font: `700 16px/32px ${theme.typography.fontFamily}`,
    },
  },
  linkWrapper: {
    display: 'flex',
    width: '503px',
    flexDirection: 'column',
    alignItems: 'center',
    '@media (max-width: 1024px)': {
      width: '100%',
    },
  },
  link: {
    display: 'flex',
    padding: '24px 16px',
    alignItems: 'flex-start',
    gap: '20px',
    alignSelf: 'stretch',
    width: 500,
    '@media (max-width: 1024px)': {
      width: '100%',
    },
  },
  line: {
    width: '503px',
    height: '1px',
    background: '#EEF0F2',
    '@media (max-width: 1024px)': {
      width: '100%',
    },
  },
  linkQuestion: {
    color: '#1C1C1C',
    fontFeatureSettings: "'clig' off, 'liga' off",
    fontFamily: '"Noto Sans JP"',
    fontSize: '18px',
    fontStyle: 'normal',
    fontWeight: 700,
    lineHeight: '32px',
    letterSpacing: '0.415px',
    flex: '1 0 0',
  },
  linkArrow: {
    width: '24px',
    height: '24px',
  },
}));
