// @flow
import { connect } from 'react-redux';
import React, { useEffect } from 'react';
// import HomeTopBanner20240311 from 'src/components/HomePage/HomeTopBanner20240311';
import HomeTopBannerTCVM from 'src/components/HomePage/HomeTopBannerTCVM';
import HomeSlider from 'src/components/HomePage/HomeSlider';
import StepsWithBrownBackground from 'src/components/StepsWithBrownBackground';
import HomeHowToPay from 'src/components/HomePage/HomeHowToPay';
import HomePaymentMethods from 'src/components/HomePage/HomePaymentMethods';
import HomeSecurity from 'src/components/HomePage/HomeSecurity';
// import FraudPSALink from '../components/FraudPSALink';
import { HOME_FAQS, SITE_METADATA } from '../constants';
import { fetchEditorsPicks } from '../redux/editors-pick';
import Layout from '../components/Layout/Layout';
import WhereToShop from '../components/WhereToShop/WhereToShop';
import FAQLinks from '../components/FAQ/FAQLinks';
import styles from '../styles/pages/index.module.scss';
import EditorPicks from '../components/EditorPicks/EditorPicks';
import { fetchCampaigns } from '../redux/campaign';

const IndexPage = ({ _fetchEditorsPicks, _fetchCampaigns, editorsPicks }) => {
  useEffect(() => {
    _fetchEditorsPicks();
    _fetchCampaigns();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Layout
      isCompanyPage
      SEOProps={{
        title: SITE_METADATA.top.title,
        description: SITE_METADATA.top.description,
      }}
    >
      <HomeTopBannerTCVM />
      <HomeSlider />
      {/* <FraudPSALink /> */}
      <StepsWithBrownBackground />
      <WhereToShop
        limit={12}
        seeMoreText="もっと詳しく"
        style={{
          background: '#F0F0F0',
        }}
        title="AmazonやQoo10などいつものお店であと払い"
      />
      <HomeHowToPay />
      <HomePaymentMethods />
      <HomeSecurity />
      <EditorPicks
        items={editorsPicks}
        style={{ backgroundColor: '#fafafa', padding: '64px 16px' }}
        className={styles.editorPicksWrapper}
      />
      <FAQLinks questions={HOME_FAQS} />
    </Layout>
  );
};

const mapStateToProps = (state) => ({
  editorsPicks: state.editorsPick.data,
  campaigns: state.campaign.data,
});

const mapDispatchToProps = (dispatch) => ({
  _fetchEditorsPicks: () => dispatch(fetchEditorsPicks()),
  _fetchCampaigns: () => dispatch(fetchCampaigns()),
});

export default connect(mapStateToProps, mapDispatchToProps)(IndexPage);
