// @flow
import React from 'react';

import { Box, Container } from '@material-ui/core';
import cx from 'classnames';
import { OutboundLink } from 'gatsby-plugin-google-gtag';
import { styles } from './styles';
import arrowRightIcon from '../../images/arrowRightFAQ.svg';
import LinearCTAButton from '../LinearCTAButton';

const FAQAccordion = ({ questions = [], hideButton, classNameWrapper }) => {
  const classes = styles();

  return (
    <div className={cx(classes.wrapper, classNameWrapper)}>
      <Container maxWidth="md" className={classes.container}>
        <h2 className={classes.title}>よくあるご質問</h2>
        <Box className={classes.innerLinksBlock}>
          {questions.map(({ question, link }, i) => (
            <Link
              key={`${question}-${i}`}
              question={question}
              link={link}
              classes={classes}
            />
          ))}
        </Box>
        {!hideButton && (
          <LinearCTAButton
            text="その他のよくあるご質問を見る"
            to="https://faq.paidy.com/"
            isExternal
          />
        )}
      </Container>
    </div>
  );
};

const Link = ({ question, link, classes }) => {
  return (
    <div className={classes.linkWrapper}>
      <div className={classes.link}>
        <OutboundLink
          href={link}
          target="_blank"
          rel="noreferrer"
          className={classes.linkQuestion}
        >
          {question}
        </OutboundLink>
        <img
          alt="go to link"
          className={classes.linkArrow}
          src={arrowRightIcon}
        />
      </div>
      <div className={classes.line} />
    </div>
  );
};

export default FAQAccordion;
