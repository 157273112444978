import React from 'react';
import PaymentHowToPay from '../Payments/PaymentHowToPay';
import styles from './HomePaymentMethods.module.scss';

export default function HomePaymentMethods() {
  return (
    <PaymentHowToPay
      noCTAButton
      customClassName={{ section: styles.section }}
    />
  );
}
